<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 6V5a5 5 0 1 1 10 0v1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2Zm8-1v1H5V5a3 3 0 1 1 6 0Z"
      fill="currentColor"
    />
  </svg>
</template>
