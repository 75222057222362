<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="m4.067.467.058-.05C4.742-.127 5.736-.14 6.372.384c.325.268.51.644.51 1.036V4.311A1.778 1.778 0 0 1 8 3.924c.917 0 1.662.654 1.662 1.462v.169c.297-.395.806-.656 1.384-.656.813 0 1.487.514 1.631 1.192.252-.135.547-.217.862-.217.917 0 1.661.655 1.661 1.462v2.827c0 1.517-.879 2.855-2.215 3.644A2.194 2.194 0 0 1 10.792 16H6.318a2.194 2.194 0 0 1-2.194-2.193l-1.841-1.178C1.355 12.033.8 11.074.8 10.065V8.8c0-.445.23-.865.592-1.142l1.568-.732V8.31c0 .268.112.488.304.488.224 0 .305-.219.305-.488V1.525c0-.397.179-.777.497-1.058Z"
      fill="currentColor"
    />
  </svg>
</template>
